import { Box, Button, Container, Typography } from '@mui/material';
import { MdOrUp, SmOrDown } from '@ocodelib/ui-common';
// import type { Settings } from 'react-slick';
// import 'slick-carousel/slick/slick-theme.css';
// import 'slick-carousel/slick/slick.css';
import WaveEffect from './WaveEffect';
import dynamic from 'next/dynamic';

const Player = dynamic(() => import('@lottiefiles/react-lottie-player').then((m) => m.Player));

// slick setting
// const settings: Settings = {
//   dots: true,
//   arrows: false,
//   infinite: true,
//   speed: 700,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   autoplay: true,
//   autoplaySpeed: 4000,
//   pauseOnHover: true,
//   draggable: true,
//   vertical: false,
// };

// const FILE_NAMES = ['lottie_home.json'] as const;
// type FileNameKey = TupleToUnion<typeof FILE_NAMES>;

// const urlOf = datiUrlBuilder<FileNameKey>({
//   datiId: 'lottie',
//   currentFolderPath: 'ai_train_result',
//   urlRelative: false,
// });

export default function HomeTopIntro() {
  return (
    <Box
      className="HomeTopIntor-root"
      sx={{
        position: 'relative',
        width: '100%',
        height: { xs: 680, md: 900 },
        overflow: 'hidden',
        pb: 4,
      }}
    >
      <MdOrUp>
        <Box
          className="HomeTopIntro-lottieBox"
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
            maxWidth: 1300,
          }}
        >
          <Player
            autoplay
            loop
            keepLastFrame
            src="/_static/images/lottie_home.json"
            style={{ width: '100%' }}
          />
        </Box>
      </MdOrUp>
      <Container
        maxWidth="lg"
        sx={{
          position: 'relative',
          height: '100%',
        }}
      >
        <Box
          className="HomeTopIntro-left"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // position: 'absolute',
            // top: '45%',
            // left: { xs: '50%', md: 0 },
            // transform: { xs: 'translate(-50%, -50%)', md: 'translate(0, -50%)' },
            width: '100%',
            p: { xs: '10px 40px 10px 40px', md: '20px 40px 20px 40px' },
            mt: 10,
            zIndex: 999,
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: { xs: '2.5rem', md: '3.5rem' },
            }}
          >
            모두를 위한
            <br />
            AI⬝코딩 교육 서비스!
          </Typography>

          <Typography variant="h5" sx={{ mt: 3, fontSize: { xs: '1.1rem', md: '1.3rem' } }}>
            교과목 연계 학습부터 인공지능, 블록코딩 교육까지!
            <br />
            학생과 선생님 모두 만족할 Ocode, 함께해 보세요.
          </Typography>
          <Button
            variant="contained"
            sx={{
              width: { xs: 212, md: 243 },
              height: { xs: 56, md: 64 },
              mt: { xs: 5, md: 9 },
              borderRadius: '40px',
              background: '#00ACFF',
              boxShadow: `inset 24px 20px 30px #A5E2FF,
                  inset -16px -10px 20px rgba(0,0,0,0.25),
                  14px 14px 30px rgba(0,0,0,0.25)`,
              '&:hover': {
                background: '#00ACFF',
                boxShadow: `inset 24px 20px 30px #A5E2FF,
                  inset -16px -10px 20px rgba(0,0,0,0.25),
                  14px 14px 30px rgba(0,0,0,0.25)`,
              },
            }}
          >
            <Typography variant="h6" fontWeight={500}>
              SEE MORE!
            </Typography>
          </Button>
        </Box>
        <SmOrDown>
          <Box
            className="HomeTopIntro-lottieBox"
            sx={{
              position: 'absolute',
              left: 0,
              bottom: 0,
              width: '100%',
            }}
          >
            <Player
              autoplay
              loop
              keepLastFrame
              src="/_static/images/lottie_home.json"
              style={{ width: '100%', height: '100%' }}
            />
          </Box>
        </SmOrDown>
      </Container>
      <MdOrUp>
        <Box>
          <WaveEffect />
        </Box>
      </MdOrUp>
    </Box>
  );
}
