'use client';

import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { Box, styled, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import SubjectTabPanel from './SubejctTabPanel';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TabProps {
  label: React.ReactNode;
  btcolor: string;
  selectcolor: string;
  icon?: string | React.ReactElement;
  iconPosition?: 'top' | 'bottom' | 'start' | 'end';
}

const SubTab = styled((props: TabProps) => <Tab disableRipple {...props} />)(
  ({ theme, btcolor, selectcolor }) => ({
    padding: 0,
    minHeight: '90%',
    background: '#fff',
    color: '#4E5052',
    borderRadius: '4px 4px 0 0',
    boxShadow: '2px 4px 10px rgba(0,0,0,0.25)',
    borderTop: `6px solid ${btcolor}`,
    transition: '0.3s',
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    '&.Mui-selected': {
      minHeight: 55,
      background: selectcolor,
      color: '#fff',
      fontWeight: 500,
      borderRadius: '15px 15px 0 0',
      [theme.breakpoints.up('xs')]: {
        width: 130,
        fontSize: '1.3rem',
      },
      [theme.breakpoints.up('md')]: {
        width: 170,
        fontSize: '1.5rem',
      },
      '& .MuiSvgIcon-root': {
        display: 'block',
      },
    },
    [theme.breakpoints.up('xs')]: {
      minWidth: 65,
      fontSize: '1rem',
      fontWeight: 500,
    },
    [theme.breakpoints.up('md')]: {
      minWidth: 90,
      fontSize: '1.1rem',
    },
  }),
);

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ flex: 1 }}
    >
      {value === index && <Box sx={{ height: '100%' }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SubjectTab() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box
      className="SubjectTab-root"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        p: 1,
      }}
    >
      <Box
        sx={{
          '& .MuiTabs-flexContainer ': {
            width: '100%',
            height: 55,
            alignItems: 'flex-end',
          },
          '& .MuiTabs-indicator': {
            display: 'none',
          },
        }}
      >
        <Tabs value={value} onChange={handleChange}>
          <SubTab
            label="초등수학"
            btcolor="#ffb100"
            selectcolor="#ffb100"
            icon={<CalculateOutlinedIcon />}
            iconPosition="start"
          />
          <SubTab
            label="중학수학"
            btcolor="#ff9e00"
            selectcolor="#ff9e00"
            icon={<CalculateOutlinedIcon />}
            iconPosition="start"
          />
          <SubTab
            label="고등수학"
            btcolor="#ff8d00"
            selectcolor="#ff8d00"
            icon={<CalculateOutlinedIcon />}
            iconPosition="start"
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <SubjectTabPanel
          title="여러가지 그래프1"
          tagTitle="6-1학기"
          bgColor="#ffb100"
          imgUrl="/_static/images/subjectTab_thumnail1.png"
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <SubjectTabPanel
          title="여러가지 그래프2"
          tagTitle="1-2학기"
          bgColor="#ff9e00"
          imgUrl="/_static/images/subjectTab_thumnail2.png"
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <SubjectTabPanel
          title="여러가지 그래프3"
          tagTitle="2-3학기"
          bgColor="#ff8d00"
          imgUrl="/_static/images/subjectTab_thumnail3.png"
        />
      </CustomTabPanel>
    </Box>
  );
}
