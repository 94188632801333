import { Box, Button, Container, Unstable_Grid2 as Grid2, Typography } from '@mui/material';
import { HakgyoansimKkwabaegiOTFR } from '@repo-ui/font';
import dynamic from 'next/dynamic';

const Player = dynamic(() => import('@lottiefiles/react-lottie-player').then((m) => m.Player));

export default function HomeBlockIntro() {
  return (
    <Container
      maxWidth="lg"
      className="HomeBlockIntro-root"
      sx={{
        position: 'relative',
        minHeight: 'calc(100vh - 64px )',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: { xs: 'center', md: 'start' },
        py: 15,
      }}
    >
      <Grid2 container columnSpacing={{ xs: 0, md: 10 }} rowSpacing={5} sx={{ width: '100%' }}>
        <Grid2
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', md: 'flex-start' },
          }}
        >
          <Box
            className="HomeBlockIntro-left"
            sx={{
              pb: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: { xs: 'center', md: 'flex-start' },
              '& > .MuiTypography-root:nth-child(1)': {
                fontSize: { xs: '2.95rem', md: '3.5rem' },
                fontFamily: HakgyoansimKkwabaegiOTFR.style,
                fontWeight: 500,
                lineHeight: 1.2,
              },
            }}
          >
            <Typography>
              한층 더 깊어진
              <br />
              블록코딩!
            </Typography>

            <Typography
              variant="h5"
              sx={{
                mt: 3,
                fontSize: { xs: '1.2rem', md: '1.3rem' },
              }}
            >
              물리엔진이 적용된 새로운 블록코딩,
              <br />
              Ocode에서 경험해 보세요.
            </Typography>

            <Button
              variant="contained"
              size="large"
              sx={(theme) => ({
                width: 212,
                height: 56,
                borderRadius: '8px',
                mt: 8,
                px: 4,
                boxShadow: theme.shadows,
                background: '#00ACFF',
                '&:hover': {
                  background: '#00ACFF',
                },
              })}
            >
              <Typography variant="h6">블록코딩 하러 가기</Typography>
            </Button>
          </Box>
        </Grid2>

        <Grid2 xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            className="HomeBlockIntro-right"
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: { xs: 400, md: 600 },
              height: { xs: 254, md: 454 },
              mt: { xs: 2, md: 0 },
            }}
          >
            <Player
              autoplay
              loop
              keepLastFrame
              src="/_static/images/lottie_block.json"
              style={{ width: '100%' }}
            />
          </Box>
        </Grid2>
      </Grid2>
    </Container>
  );
}
