import GreatGalleryCard from '@/features/features-components/GalleryCard/GreatGalleryCard';
import RowGalleryCard from '@/features/features-components/GalleryCard/RowGalleryCard';
import { MdOrUp, SmOrUp, XsOrDown } from '@ocodelib/ui-common';
import { galleryTempData } from '@/features/features-components/GalleryCard/galleryTempData';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import {
  Box,
  Button,
  Container,
  FormControl,
  Unstable_Grid2 as Grid2,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useState } from 'react';

type WeekSortTypeKey = 'WEEK1' | 'WEEK2' | 'WEEK3' | 'WEEK4';
const WeekSortType: Record<WeekSortTypeKey, string> = {
  WEEK1: '7월 1주차',
  WEEK2: '7월 2주차',
  WEEK3: '7월 3주차',
  WEEK4: '7월 4주차',
};

export default function HomeGreatWorkIntro() {
  const [sortType, setSortType] = useState('');
  const hanldeChangeSortType = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (typeof value === 'string' && value in WeekSortType) {
      setSortType(value);
    }
  };
  return (
    <Box
      className="HomeGreatWorkIntro-root"
      sx={{
        width: '100%',
        background: '#00acff',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        py: 10,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid2
          container
          columnSpacing={{ xs: 0, md: 10 }}
          rowSpacing={5}
          sx={{
            width: '100%',
            my: 3,
            justifyContent: 'space-between',
            px: { xs: 3, sm: 0 },
          }}
        >
          <Grid2
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Box className="HomeGreatWorkIntro-title" sx={{ position: 'relative' }}>
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '2.3rem', md: '3rem' },
                  fontWeight: { xs: 700, md: 900 },
                  color: '#fff',
                }}
              >
                우수 작품
              </Typography>
              {/* left */}
              <MdOrUp>
                <Box
                  component="img"
                  src="/_static/images/bay_left.png"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: -40,
                  }}
                />
              </MdOrUp>

              {/* right */}
              <MdOrUp>
                <Box
                  component="img"
                  src="/_static/images/bay_right.png"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    right: -40,
                  }}
                />
              </MdOrUp>
            </Box>

            <Box className="HomeGreatWorkIntro-sortDropDown">
              <FormControl>
                <Select
                  defaultValue="WEEK1"
                  onChange={hanldeChangeSortType}
                  size="small"
                  margin="dense"
                  variant="outlined"
                  displayEmpty
                  sx={{
                    ml: { xs: 1, md: 5 },
                    px: 1,
                    color: '#fff',
                    '& svg': {
                      color: '#fff',
                    },
                    '& fieldset': {
                      display: 'none',
                    },
                  }}
                >
                  {Object.entries(WeekSortType).map(([key, label]) => (
                    <MenuItem value={key} key={key}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid2>

          <Grid2 md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Box className="HomeGreatWorkIntro-moreBtn">
              <Button
                size="small"
                variant="text"
                endIcon={<ChevronRightOutlinedIcon />}
                sx={{
                  color: '#fff',
                }}
              >
                더보기
              </Button>
            </Box>
          </Grid2>
        </Grid2>

        <Box
          className="HomeGreatWorkIntro-galleryList"
          sx={{
            width: '100%',
            mb: 5,
            px: { xs: 3, md: 8 },
          }}
        >
          <SmOrUp>
            <Grid2 container spacing={3} sx={{ justifyContent: 'center' }}>
              {galleryTempData.map((gallery, idx) => (
                <Grid2
                  xs={6}
                  md={3}
                  key={idx}
                  sx={{
                    maxWidth: 282,
                    display: 'flex',
                    justifyContent: 'center',
                    '& .MuiPaper-root': {
                      '&:hover': {
                        boxShadow: '2px 4px 20px rgba(0,0,0,0.5)',
                        transform: 'translateY(-16px)',
                      },
                    },
                  }}
                >
                  <GreatGalleryCard
                    thumbnailUrl={gallery.thumbnailUrl}
                    title={gallery.title}
                    userId={gallery.userId}
                    seenCount={gallery.seenCount}
                    replyCount={gallery.replyCount}
                    favorCount={gallery.favorCount}
                    tag={gallery.tag}
                  />
                </Grid2>
              ))}
            </Grid2>
          </SmOrUp>

          <XsOrDown>
            <Grid2 container spacing={1}>
              {galleryTempData.map((gallery, idx) => (
                <Grid2 xs={12} key={idx} sx={{ display: 'flex', justifyContent: 'center' }}>
                  <RowGalleryCard
                    thumbnailUrl={gallery.thumbnailUrl}
                    title={gallery.title}
                    userId={gallery.userId}
                    seenCount={gallery.seenCount}
                    replyCount={gallery.replyCount}
                    favorCount={gallery.favorCount}
                    tag={gallery.tag}
                  />
                </Grid2>
              ))}
            </Grid2>
          </XsOrDown>
        </Box>
      </Container>
    </Box>
  );
}
