import { MdOrUp } from '@ocodelib/ui-common';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import {
  Box,
  Button,
  ButtonBase,
  Container,
  Unstable_Grid2 as Grid2,
  Typography,
} from '@mui/material';
import GalleryCate from './HomePopluarWorkIntro/GalleryCate';
import GalleryList from './HomePopluarWorkIntro/GalleryList';
import LedAnimaiton from './HomePopluarWorkIntro/LedAnimaiton';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';

export default function HomePopularWorkIntro() {
  return (
    <Box
      className="HomePopularWorkIntro-root"
      sx={{
        width: '100%',
        minHeight: 'calc(100vh - 64px )',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pt: 5,
        pb: 10,
        background: '#E1F5FF',
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          px: 1,
        }}
      >
        <Grid2
          container
          columnSpacing={{ xs: 0, md: 10 }}
          rowSpacing={5}
          sx={{
            width: '100%',
            my: 3,
            justifyContent: 'space-between',
            px: { xs: 3, sm: 0 },
          }}
        >
          <Grid2
            md={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Box className="HomePopularWorkIntro-title">
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '2.3rem', md: '3rem' },
                  fontWeight: { xs: 700, md: 900 },
                }}
              >
                인기 작품
              </Typography>
            </Box>

            <Box
              className="HomePopularWorkIntro-dateBox"
              sx={{ display: 'flex', alignItems: 'center', ml: 4 }}
            >
              <Typography
                variant="h6"
                sx={{
                  fontSize: '1rem',
                  fontWeight: 500,
                }}
              >
                07/04 11:51
              </Typography>
              <ButtonBase
                sx={{
                  ml: 1,
                }}
              >
                <RefreshOutlinedIcon
                  sx={{
                    width: 20,
                  }}
                />
              </ButtonBase>
            </Box>
          </Grid2>

          <Grid2 md={6} sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <Box className="HomePopularWorkIntro-moreBtn">
              <Button
                size="small"
                variant="text"
                sx={{
                  color: '#12a2ff',
                }}
                endIcon={<ChevronRightOutlinedIcon />}
              >
                더보기
              </Button>
            </Box>
          </Grid2>
        </Grid2>

        <Box
          className="HomePopularWorkIntro-galleryBox"
          sx={{
            width: '98%',
          }}
        >
          <Box
            sx={{
              px: 3,
              py: 2,
              display: 'flex',
              justifyContent: 'space-between',
              background: '#00ACFF',
              borderRadius: '20px 20px 0 0',
              boxShadow: 'inset 16px 10px 20px #A5E2FF',
            }}
          >
            <GalleryCate />
            {/* led animation */}
            <MdOrUp>
              <LedAnimaiton />
            </MdOrUp>
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              borderRadius: '0 0 20px 20px',
              background: '#90daff',
              boxShadow: `inset 24px 20px 30px #A5E2FF,
                inset -16px -10px 20px rgba(0,0,0,0.25),
                14px 14px 30px rgba(0,0,0,0.25)`,
            }}
          >
            <Box sx={{ py: 5 }}>
              <GalleryList />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
