import { MdOrUp } from '@ocodelib/ui-common';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import { Box, Button, Container, Unstable_Grid2 as Grid2, Typography } from '@mui/material';
import { HakgyoansimKkwabaegiOTFR } from '@repo-ui/font';
import SubjectTab from './SubjectTab';

export default function HomeSubjectIntro() {
  return (
    <Box
      className="HomeSubjectIntro-root"
      sx={{
        position: 'relative',
        width: '100%',
        minHeight: 'calc(100vh - 300px )',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: 15,
        '& .MuiContainer-root': {
          maxWidth: 1380,
        },
      }}
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid2
          container
          rowSpacing={5}
          sx={{ width: '100%', justifyContent: { xs: 'center', md: 'space-between' } }}
        >
          <Grid2
            xs={12}
            md={6}
            sx={{
              maxWidth: 616,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              order: { xs: 2, md: 1 },
              p: 0,
            }}
          >
            <Box
              className="HomeSubjectIntro-left"
              sx={{
                mt: { xs: 10, md: 6 },
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <SubjectTab />
            </Box>
          </Grid2>

          <Grid2
            xs={12}
            md={6}
            sx={{
              maxWidth: 616,
              px: 5,
              display: 'flex',
              justifyContent: 'center',
              textAlign: { xs: 'center', md: 'start' },
              order: { xs: 1, md: 2 },
            }}
          >
            <Box
              className="HomeSubjectIntro-right"
              sx={{
                width: 444,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: { xs: 'center', md: 'flex-start' },
                '& > .MuiTypography-root:nth-child(1)': {
                  fontSize: { xs: '2.95rem', md: '3.5rem' },
                  fontFamily: HakgyoansimKkwabaegiOTFR.style,
                  fontWeight: 500,
                },
              }}
            >
              <Typography>교과서와 함께!</Typography>

              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  fontSize: { xs: '1.1rem', md: '1.3rem' },
                }}
              >
                교과목과 연계되는 여러 학습 자료들로
                <br />
                공부해 보세요.
              </Typography>

              <MdOrUp>
                <Box
                  sx={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                    borderRadius: '15px',
                    background: 'rgba(255, 255, 255, 0.25 )',
                    p: 2,
                    mt: 3,
                    mb: { xs: 3, md: 0 },
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      p: 1,
                      mr: 2,
                      textAlign: 'center',
                      color: '#fff',
                    }}
                  >
                    초등수학
                    <br />
                    6-1
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      pl: 3,
                      borderLeft: '1px solid #fff',
                      color: '#26282A',
                    }}
                  >
                    1. 분수의 나눗셈
                    <br />
                    2. 각기둥과 각뿔
                    <br />
                    3. 소수의 나눗셈
                    <br />
                    4. 비와 비율
                    <br />
                    5. 여러 가지 그래프
                    <br />
                  </Typography>
                </Box>
              </MdOrUp>
              <Button
                size="large"
                endIcon={<ArrowOutwardOutlinedIcon />}
                sx={{
                  mt: 4,
                  width: 212,
                  height: 56,
                  borderRadius: '8px',
                  background: '#fff',
                  color: '#000',
                  '&:hover': {
                    background: '#fff',
                  },
                }}
              >
                <Typography variant="h6">교과목 바로가기</Typography>
              </Button>
            </Box>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
}
