import { Box } from '@mui/material';
import HomeBlockIntro from './components/HomeBlockIntro';
import HomeGreatWorkIntro from './components/HomeGreatWorkIntro';
import HomeNoticeIntro from './components/HomeNoticeIntro/HomeNoticeIntro';
import HomePopularWorkIntro from './components/HomePopularWorkIntro';
import HomeTopIntro from './components/HomeTopIntro';
import HomeAiIntro from './components/HomeAiIntro';
import HomeSubjectIntro from './HomeSubjectionIntro/HomeSubjectIntro';
import { MdOrUp } from '@ocodelib/ui-common';

export function HomeMain() {
  return (
    <Box
      className="HomeMain-root"
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <HomeTopIntro />
      <Box
        sx={{
          width: '100%',
          backgroundImage: 'linear-gradient(#00ACFF, #189CFF 35%, #9747FF 75%)',
        }}
      >
        <HomeSubjectIntro />
        <HomeAiIntro />
      </Box>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          backgroundImage: {
            xs: 'linear-gradient( #9747FF 7%, #fff 7%)',
            md: 'linear-gradient( #9747FF 20%, #fff 20%)',
          },
        }}
      >
        {/* 배경레이어 */}
        <MdOrUp>
          <Box
            component="img"
            src="/_static/images/home_coral.png"
            sx={{
              position: 'absolute',
              width: '100%',
              top: { xs: -30, md: -100 },
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </MdOrUp>
        <HomeBlockIntro />
      </Box>
      <HomeGreatWorkIntro />
      <HomePopularWorkIntro />
      <HomeNoticeIntro />
    </Box>
  );
}
