'use client';

import { Box, keyframes } from '@mui/material';

export default function WaveEffect() {
  const waveAnimation = keyframes`
  0%,100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(10px);
  }
`;

  return (
    <Box
      sx={{
        width: '100%',
        '& .wave': {
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: 200,
          animation: `${waveAnimation} 4s ease-in-out infinite`,
        },
        '& .wave:nth-child(1)': {
          zIndex: 1000,
          animationDelay: '0s',
          background: `url('/_static/images/wave1.png')`,
        },
        '& .wave:nth-child(2)': {
          zIndex: 999,
          animationDelay: '-5s',
          background: `url('/_static/images/wave2.png')`,
        },
        '& .wave:nth-child(3)': {
          zIndex: 998,
          animationDelay: '-2s',
          background: `url('/_static/images/wave3.png')`,
        },
      }}
    >
      <Box className="wave" />
      <Box className="wave" />
      <Box className="wave" />
      <Box className="wave" />
    </Box>
  );
}
