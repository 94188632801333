'use client';

import { MdOrUp, SmOrDown, SmOrUp } from '@ocodelib/ui-common';
import {
  Box,
  ButtonBase,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { cateData } from './cateData';
import { useState } from 'react';

type GallerySortTypeKey = 'ENTIRE' | 'GAME' | 'COMIC' | 'STORY' | 'LEARNING' | 'CLASS';
const GallerySortType: Record<GallerySortTypeKey, string> = {
  ENTIRE: '전체',
  GAME: '게임',
  COMIC: '만화',
  STORY: '스토리',
  LEARNING: '학습',
  CLASS: '수업',
};

export default function GalleryCate() {
  const [sortType, setSortType] = useState('');
  const hanldeChangeSortType = (event: SelectChangeEvent) => {
    const value = event.target.value;
    if (typeof value === 'string' && value in GallerySortType) {
      setSortType(value);
    }
  };
  return (
    <Box
      className="GalleryCate-root"
      sx={{
        display: 'flex',
        ml: 4,
      }}
    >
      <MdOrUp>
        {cateData.length > 0 &&
          cateData.map((cate, idx) => (
            <ButtonBase
              key={idx}
              sx={{
                borderRadius: '20px',
                justifyContent: 'flex-start',
                gap: 1,
                px: 2,
                py: 0.5,
                color: 'rgba(255,255,255, 0.9)',
                '&:hover': {
                  color: 'rgba(255,255,255, 1)',
                },
                transition: '0.1s',
              }}
            >
              <Typography variant="body1">{cate.title}</Typography>
            </ButtonBase>
          ))}
      </MdOrUp>

      <SmOrDown>
        <FormControl>
          <Select
            defaultValue="ENTIRE"
            onChange={hanldeChangeSortType}
            size="small"
            margin="dense"
            variant="outlined"
            displayEmpty
            sx={{
              ml: 1,
              px: 1,
              color: '#fff',
              '& svg': {
                color: '#fff',
              },
              '& fieldset': {
                display: 'none',
              },
            }}
          >
            {Object.entries(GallerySortType).map(([key, label]) => (
              <MenuItem value={key} key={key}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </SmOrDown>
    </Box>
  );
}
