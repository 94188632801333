import { Box, Button, Typography } from '@mui/material';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';

interface Props {
  title: string;
  tagTitle: string;
  bgColor: string;
  imgUrl: string;
}

export default function SubjectTabPanel(props: Props) {
  const { title, tagTitle, bgColor, imgUrl } = props;
  return (
    <Box
      className="SubejctTabPanel-root"
      sx={{
        height: '85%',
        display: 'flex',
        flexDirection: 'column',
        borderRadius: '0 30px 30px 30px',
        overflow: 'hidden',
        boxShadow: '2px 4px 20px rgba(0,0,0,0.25)',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: { xs: 60, md: 75 },
          px: { xs: 2, md: 4 },
          py: 3,
          background: bgColor,
        }}
      >
        <Box sx={{ display: 'flex' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              height: 25,
              borderRadius: '15px',
              background: '#805900',
              color: '#fff',
              fontSize: { xs: '0.4rem', md: '0.7rem' },
              px: 2,
            }}
          >
            {tagTitle}
          </Box>

          <Typography
            variant="h6"
            ml={1}
            sx={{
              fontWeight: 500,
              fontSize: {
                xs: '1rem',
                md: '1.125rem',
              },
            }}
          >
            {title}
          </Typography>
        </Box>
        <Box className="SubejctTab-moreBtn">
          <Button
            size="small"
            variant="text"
            sx={{
              fontWeight: 300,
              color: '#fff',
            }}
            endIcon={<ChevronRightOutlinedIcon />}
          >
            자세히 보기
          </Button>
        </Box>
      </Box>

      <Box
        className="SubejctTab-imgBox"
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contained',
          },
        }}
      >
        <img src={imgUrl} />
      </Box>
    </Box>
  );
}
