'use client';

import { Box } from '@mui/material';
import { useEffect } from 'react';

const ledColors = ['green', 'yellow', 'pink'];
export default function LedAnimaiton() {
  let activeLight = 0;
  useEffect(() => {
    const circles = document.querySelectorAll('.LedAnimaiton-circle');
    const id = setInterval(changeLight(circles), 2000);
    return () => clearInterval(id);
  }, []);

  const changeLight = (circles: NodeListOf<Element>) => () => {
    const circle = circles[activeLight];
    if (circle) {
      circle.className = 'LedAnimaiton-circle';
      activeLight++;
    }
    if (activeLight > 2) activeLight = 0;
    const color = ledColors[activeLight];
    if (color) {
      circles[activeLight]?.classList.add(color);
    }
  };

  return (
    <Box
      className="LedAnimaiton-root"
      sx={{
        mr: 4,
        width: 130,
        height: 40,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 3,
        '& .LedAnimaiton-circle': {
          width: 15,
          height: 15,
          borderRadius: '50%',
          backgroundColor: '#e1f5ff',
          boxShadow: 'inset 5px 5px 5px -5px #888',
        },
        '& .LedAnimaiton-circle.green': {
          backgroundColor: '#83FF00',
          boxShadow: `0 0 5px 2px #83FF00`,
        },
        '& .LedAnimaiton-circle.yellow': {
          backgroundColor: '#E7FF39',
          boxShadow: `0 0 5px 2px #E7FF39`,
        },
        '& .LedAnimaiton-circle.pink': {
          backgroundColor: '#FF8CF4',
          boxShadow: `0 0 5px 2px #FF8CF4`,
        },
      }}
    >
      <Box className="LedAnimaiton-circle green" />
      <Box className="LedAnimaiton-circle" />
      <Box className="LedAnimaiton-circle" />
    </Box>
  );
}
