export const questions = [
  {
    question: '다른 블록코딩과 뭐가 달라요?',
  },

  {
    question: '만약 질문이 두 줄인 경우에는\n이런 식으로 적습니다.',
  },

  {
    question: '다른 종류의 질문 입니다.',
  },

  {
    question: '다른 블록코딩가 뭐가 달라요?',
  },

  {
    question: '만약 질문이 두 줄인 경우에는\n이런 식으로 적습니다.',
  },
];
