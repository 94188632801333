import { LgOrUp, MdOrUp, SmOrDown, SmOrUp } from '@ocodelib/ui-common';
import { Box, Paper, Typography } from '@mui/material';
import * as React from 'react';

export default function NoticeCard(props: {
  imgUrl: string;
  bgColor: string;
  titleColor?: string;
  title: string;
  desc: string;
}) {
  const { imgUrl, title, desc, bgColor, titleColor } = props;
  return (
    <Paper
      className="NoticeCard-root"
      elevation={0}
      sx={{
        width: '100%',
        maxHeight: { xs: 300, md: 500 },
        background: bgColor,
        borderRadius: '30px',
        p: 3,
        whiteSpace: 'pre-line',
        overflow: 'hidden',
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          sx={{
            position: 'relative',
            mt: 1,
            fontSize: '1.8rem',
            lineHeight: '2rem',
            color: titleColor,
            fontWeight: '700',
            whiteSpace: 'pre-line',
          }}
        >
          {title}
        </Typography>
        <LgOrUp>
          <Box>
            <Typography variant="body2">2024.07.02</Typography>
          </Box>
        </LgOrUp>
      </Box>

      <Box className="NoticeCard-card">
        <Box
          className="NoticeCard-cta"
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            background: '#F0F8FF`',
          }}
        >
          <Box minHeight={38} sx={{ alignSelf: 'flex-start', mt: 2 }}>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
              {desc}
            </Typography>
            <SmOrDown>
              <Typography variant="body2" mt={1}>
                2024.07.02
              </Typography>
            </SmOrDown>
          </Box>
          <Box className="NoticeCard-cardImgBox" sx={{ zIndex: 1, mt: 2, width: 216 }}>
            <img src={imgUrl} alt="" />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
