export const cateData = [
  {
    title: '전체',
  },

  {
    title: '게임',
  },

  {
    title: '만화',
  },

  {
    title: '스토리',
  },

  {
    title: '학습',
  },

  {
    title: '수업',
  },
];
