import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import { Box, ButtonBase, Paper, Typography } from '@mui/material';
import { routerPush } from '@repo-ui/router';

interface Props {
  thumbnailUrl: string;
  title: string;
  userId: string;
  seenCount: number;
  replyCount: number;
  favorCount: number;
  tag: string;
  href?: string;
}

export default function GreatGalleryCard(props: Props) {
  const { thumbnailUrl, href = '/', tag, title, userId, seenCount, replyCount, favorCount } = props;
  const handleClickMenu = (url: string) => () => {
    routerPush(href);
  };
  return (
    <Paper
      className="GreatGalleryCard-root"
      elevation={1}
      sx={{
        display: 'block',
        overflow: 'hidden',
        borderRadius: '30px',
        borderBottom: '1px solid #eee',
        boxShadow: '2px 4px 20px rgba(0,0,0,0.25)',
        mb: 2,
        maxWidth: 330,
        transition: '0.2s',
        width: {
          xs: '100%',
          sm: '100%',
        },
      }}
    >
      <ButtonBase
        sx={{
          display: 'block',
          width: '100%',
        }}
        component="div"
        onClick={handleClickMenu(href)}
      >
        {/* 작품 이미지 */}
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Box
            sx={{
              position: 'relative',
              flex: 1,
              width: '100%',
              height: '100%',
              '& img': {
                width: '100%',
                height: '100%',
              },
            }}
          >
            <img src={thumbnailUrl} alt="" />
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 15,
              left: 15,
              width: 50,
              height: 20,
              borderRadius: '20px',
              background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4))',
              color: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography sx={{ fontSize: '0.75rem', color: '#fff' }}>{tag}</Typography>
          </Box>
        </Box>
      </ButtonBase>

      <ButtonBase
        sx={{
          display: 'block',
          width: '100%',
          position: 'relative',
        }}
        component="div"
        disableRipple
        onClick={handleClickMenu(href)}
      >
        {/* 작품 제목 */}
        <Box mt={1} mb={2}>
          <Typography
            sx={{
              overflow: 'hidden',
              textAlign: 'left',
              fontSize: '1rem',
              fontWeight: 500,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              mx: 2,
              '&:hover': {
                color: 'primary.main',
                textDecoration: 'underline',
              },
            }}
          >
            {title}
          </Typography>

          <Typography
            sx={{
              overflow: 'hidden',
              textAlign: 'left',
              fontSize: '0.8rem',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              mx: 2,
              '&:hover': {
                color: 'primary.main',
                textDecoration: 'underline',
              },
            }}
          >
            {userId}
          </Typography>
        </Box>
      </ButtonBase>

      <Box sx={{ py: 1.5, px: 2, display: 'flex', gap: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontSize: '0.75rem' }}>
          <RemoveRedEyeOutlinedIcon />
          {seenCount}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontSize: '0.75rem' }}>
          <FavoriteBorderOutlinedIcon />
          {favorCount}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, fontSize: '0.75rem' }}>
          <ChatOutlinedIcon />
          {replyCount}
        </Box>
      </Box>
    </Paper>
  );
}
