import { MdOrUp, SmOrDown } from '@ocodelib/ui-common';
import { Box, Paper, Typography } from '@mui/material';
import React from 'react';

export default function NoticeSmallCard(props: {
  imgUrl: string;
  title: string;
  desc: React.ReactNode;
  bgColor: string;
}) {
  const { imgUrl, title, desc, bgColor } = props;

  return (
    <Paper
      className="NoticeSmallCard-root"
      elevation={0}
      sx={(theme) => ({
        background: bgColor,
        position: 'relative',
        width: '100%',
        height: '100%',
        p: 2,
        border: '1px solid #D4E2F2',
        borderRadius: '30px',
        [theme.breakpoints.up('sm')]: {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        },
        [theme.breakpoints.down('sm')]: {
          display: 'flex',
          flexDirection: 'column',
        },
      })}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', pl: 1 }}>
            <Typography
              sx={{
                mt: 1,
                fontSize: { xs: '1.2rem', md: '1.3rem' },
                fontWeight: '700',
                whiteSpace: 'pre-line',
              }}
            >
              {title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                mt: 1,
                whiteSpace: 'pre-line',
              }}
            >
              2024.07.02
            </Typography>
          </Box>

          <MdOrUp>
            <Box
              sx={{
                py: 1,
                mt: { xs: 5, md: 1 },
                border: '0px solid blue',
                '& img': {
                  objectFit: 'cover',
                },
              }}
            >
              <img src={imgUrl} alt="" width={120} height={90} />
            </Box>
          </MdOrUp>

          <SmOrDown>
            <Box
              sx={{
                py: 1,

                border: '0px solid blue',
                '& img': {
                  objectFit: 'contain',
                },
              }}
            >
              <img src={imgUrl} alt="" width={100} height={80} />
            </Box>
          </SmOrDown>
        </Box>
      </Box>
    </Paper>
  );
}
