import PopularGalleryCard from '@/features/features-components/GalleryCard/PopularGalleryCard';
import RowGalleryCard from '@/features/features-components/GalleryCard/RowGalleryCard';
import { galleryTempData } from '@/features/features-components/GalleryCard/galleryTempData';
import { SmOrUp, XsOrDown } from '@ocodelib/ui-common';
import { Box, Unstable_Grid2 as Grid2 } from '@mui/material';

export default function GalleryList() {
  return (
    <Box className="GalleryList-root" sx={{ px: 2 }}>
      <SmOrUp>
        <Grid2 container spacing={3} sx={{ justifyContent: 'center' }}>
          {galleryTempData.map((gallery, idx) => (
            <Grid2
              xs={6}
              md={3}
              key={idx}
              sx={{
                maxWidth: 282,
                display: 'flex',
                justifyContent: 'center',
                '& .MuiPaper-root': {
                  '&:hover': {
                    boxShadow: '2px 4px 20px rgba(0,0,0,0.5)',
                    transform: 'translateY(-16px)',
                  },
                },
              }}
            >
              <PopularGalleryCard
                thumbnailUrl={gallery.thumbnailUrl}
                title={gallery.title}
                userId={gallery.userId}
                seenCount={gallery.seenCount}
                replyCount={gallery.replyCount}
                favorCount={gallery.favorCount}
                tag={gallery.tag}
              />
            </Grid2>
          ))}
        </Grid2>
      </SmOrUp>

      <XsOrDown>
        <Grid2 container spacing={1} sx={{ justifyContent: 'center' }}>
          {galleryTempData.map((gallery, idx) => (
            <Grid2
              xs={12}
              md={3}
              key={idx}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                maxWidth: 411,
              }}
            >
              <RowGalleryCard
                thumbnailUrl={gallery.thumbnailUrl}
                title={gallery.title}
                userId={gallery.userId}
                seenCount={gallery.seenCount}
                replyCount={gallery.replyCount}
                favorCount={gallery.favorCount}
                tag={gallery.tag}
              />
            </Grid2>
          ))}
        </Grid2>
      </XsOrDown>
    </Box>
  );
}
