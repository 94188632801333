export const galleryTempData = [
  {
    thumbnailUrl: '/_static/images/gallery1.png',
    title: '코디니가 알려주는 태양에 가서 아무거나',
    userId: '사용자 ID1',
    seenCount: 25,
    replyCount: 25,
    favorCount: 25,
    tag: '태그',
  },

  {
    thumbnailUrl: '/_static/images/gallery2.png',
    title: '6.25 기념 테스트',
    userId: '사용자 ID2',
    seenCount: 25,
    replyCount: 25,
    favorCount: 25,
    tag: '태그',
  },

  {
    thumbnailUrl: '/_static/images/gallery3.png',
    title: '코디니와 십자가',
    userId: '사용자 ID3',
    seenCount: 25,
    replyCount: 25,
    favorCount: 25,
    tag: '태그',
  },

  {
    thumbnailUrl: '/_static/images/gallery4.png',
    title: '밸런스 게임!!!',
    userId: '사용자 ID4',
    seenCount: 25,
    replyCount: 25,
    favorCount: 25,
    tag: '태그',
  },
];
