import { Box, ButtonBase, Container, Unstable_Grid2 as Grid2, Typography } from '@mui/material';
import ViewComfyOutlinedIcon from '@mui/icons-material/ViewComfyOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import NoticeCard from './NoticeCard';
import NoticeSmallCard from './NoticeSmallCard';
import { questions } from './questions';
import { MdOrUp } from '@ocodelib/ui-common';
export default function HomeNoticeIntro() {
  return (
    <Box
      className="HomeNoticeIntro-root"
      sx={{
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: 10,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid2 container columns={11} spacing={2} sx={{ width: '100%', p: '20px 32px' }}>
          <Grid2
            xs={10}
            md={10}
            sx={{
              justifyContent: { xs: 'center', md: 'flex-start' },
              mb: 3,
            }}
          >
            <Box className="HomeNoticeIntro-title">
              <Typography
                variant="h2"
                sx={{
                  fontSize: { xs: '2.95rem', md: '3.5rem' },
                }}
              >
                Oh! 소식
              </Typography>
            </Box>
          </Grid2>

          <Grid2 container xs={12} md={4}>
            <Grid2 xs={12} md={12}>
              <NoticeCard
                imgUrl="/_static/images/trophy.png"
                bgColor="#ffba33"
                title="핑퐁로봇 출시!"
                desc={'핑퐁 로봇과 함께하는 블록코딩,\n지금바로 만나보세요.'}
              />
            </Grid2>
          </Grid2>

          <Grid2 container xs={12} md={4}>
            <Grid2 xs={12} md={12}>
              <NoticeSmallCard
                imgUrl="/_static/images/notice2.png"
                bgColor="#4dc4ff"
                title={'개인정보처리방침\n업데이트 공지'}
                desc="설명"
              />
            </Grid2>

            <Grid2 xs={12} md={12}>
              <NoticeSmallCard
                imgUrl="/_static/images/notice3.png"
                bgColor="#92d050"
                title={'디스크 증설 및\n서버 점검 진행'}
                desc="설명"
              />
            </Grid2>

            <Grid2 xs={12} md={12}>
              <NoticeSmallCard
                imgUrl="/_static/images/notice4.png"
                bgColor="#ffca28"
                title={'인공지능 서버\n교체 작업 진행 알림'}
                desc="설명"
              />
            </Grid2>
          </Grid2>

          <MdOrUp>
            <Grid2 container xs={12} md={3} p={1}>
              <Grid2 xs={12} md={12}>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      flexBasis: '75%',
                      background: '#EEF0F2',
                      px: 2,
                      mb: 1,
                      borderRadius: '30px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-around',
                    }}
                  >
                    {questions.map((it, idx) => (
                      <Box
                        key={idx}
                        sx={{
                          minHeight: 70,
                          display: 'flex',
                          alignItems: 'center',
                          pr: 2,
                          borderBottom: idx !== questions.length - 1 ? '1px solid #C6C8CA' : 'none',
                        }}
                      >
                        <ViewComfyOutlinedIcon />
                        <Typography
                          sx={{
                            ml: 2,
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            fontSize: { xs: '0.8rem', lg: '0.875rem' },
                            whiteSpace: { xs: 'nowrap', lg: 'pre-line' },
                          }}
                        >
                          {it.question}
                        </Typography>
                      </Box>
                    ))}
                  </Box>

                  <Box
                    sx={{
                      flexBasis: '30%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box
                      sx={{
                        flexBasis: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        p: 1,
                        '& img': {
                          width: { xs: 70, lg: 110 },
                          height: { xs: 70, lg: 110 },
                        },
                      }}
                    >
                      <img src="/_static/images/home_ask.png" alt="" />
                    </Box>

                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'space-around',
                        py: 2,
                        '& .MuiButtonBase-root': {
                          width: '100%',
                          justifyContent: 'space-between',
                        },
                      }}
                    >
                      <ButtonBase>
                        <Typography sx={{ fontSize: { xs: '1rem', lg: '1.3rem' } }}>FAQ</Typography>
                        <ChevronRightOutlinedIcon />
                      </ButtonBase>

                      <ButtonBase>
                        <Typography sx={{ fontSize: { xs: '1rem', lg: '1.3rem' } }}>
                          문의하기
                        </Typography>
                        <ChevronRightOutlinedIcon />
                      </ButtonBase>
                    </Box>
                  </Box>
                </Box>
              </Grid2>
            </Grid2>
          </MdOrUp>
        </Grid2>
      </Container>
    </Box>
  );
}
