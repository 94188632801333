import { HakgyoansimKkwabaegiOTFR } from '@repo-ui/font';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import { Box, Button, Container, Unstable_Grid2 as Grid2, Typography } from '@mui/material';
import NextImage from 'next/image';

export default function HomeAiIntro() {
  return (
    <Box
      className="HomeAiIntro-root"
      sx={{
        width: '100%',
        position: 'relative',
        minHeight: 'calc(100vh - 64px )',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pt: { xs: 15, md: 0 },
        pb: 10,
        zIndex: 10,
      }}
    >
      <Container
        maxWidth="lg"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: { xs: 'center', md: 'start' },
        }}
      >
        <Grid2 container columnSpacing={{ xs: 0, md: 10 }} rowSpacing={5} sx={{ width: '100%' }}>
          <Grid2
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', md: 'flex-start' },
            }}
          >
            <Box
              className="HomeAiIntro-left"
              sx={{
                pb: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: { xs: 'center', md: 'flex-start' },
                '& > .MuiTypography-root:nth-child(1)': {
                  fontSize: { xs: '2.95rem', md: '3.5rem' },
                  fontFamily: HakgyoansimKkwabaegiOTFR.style,
                  fontWeight: 500,
                },
              }}
            >
              <Typography>다양한 AI학습!</Typography>

              <Typography
                variant="h5"
                sx={{
                  mt: 1,
                  fontSize: { xs: '1.2rem', md: '1.3rem' },
                }}
              >
                AI학습에서는 데이터 분석,얼굴 아바타,워드 클라우드
                <br />등 다양한 컨텐츠로 쉽고 재밌게 학습할 수 있어요.
              </Typography>

              <Button
                size="large"
                endIcon={<ArrowOutwardOutlinedIcon />}
                sx={{
                  mt: 8,
                  color: '#000',
                  width: 212,
                  height: 56,
                  borderRadius: '8px',
                  background: '#fff',
                  '&:hover': {
                    background: '#fff',
                  },
                }}
              >
                <Typography variant="h6">AI학습 바로 가기</Typography>
              </Button>
            </Box>
          </Grid2>

          <Grid2
            xs={12}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              px: 0,
            }}
          >
            <Box
              className="HomeAiIntro-right"
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: { xs: 400, md: 600 },
                height: { xs: 304, md: 454 },
                mt: { xs: 2, md: 0 },
                zIndex: 10,
              }}
            >
              <NextImage
                src="/_static/images/homeImage3.png"
                width={0}
                height={0}
                sizes="100vw"
                style={{ width: '100%', height: '100%' }}
                alt=""
                priority
              />
            </Box>
          </Grid2>
        </Grid2>
      </Container>
    </Box>
  );
}
